import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { appConfig } from 'app/core/config/app.config';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'text-area',
  template: `<div class="mb-4 form-group">
    <label *ngIf="label !== ''" [for]="controlName" [class]="lableStyle">
      {{ label }}
      <span *ngIf="isRequired() && label !== ''" class="text-red-500"> * </span>
    </label>
    <textarea
      [placeholder]="placeholder"
      [formControl]="controlName"
      autocomplete="off"
      [id]="controlName"
      [name]="controlName"
      class="mt-1 w-full border border-gray-300 py-2 px-4 rounded-md form-control"
      [ngClass]="{ 'bg-gray-100': controlName.disabled }"
      [class.is-invalid]="controlName.invalid && controlName.touched"
    ></textarea>
    <span
      *ngIf="!!controlName && controlName.enabled"
      class="text-sm text-gray-700 ml-2"
    >
      Character count: {{ textCount ? textCount : '0' }} / {{ textLimit }}
    </span>
    <form-validator [controlName]="controlName"></form-validator>
    <!--  <div *ngIf="exceedsMaxLength" class="text-sm text-red-500 mt-1 ml-2">
      This field exceeds the character limit of {{ textLimit }}.
    </div> -->
  </div>`,
})
export class TextAreaComponent implements OnInit, OnDestroy {
  @Input() label: string = '';
  @Input() controlName: FormControl = new FormControl('');
  @Input() placeholder: string = '';
  @Input() setLimit: number = 0;
  @Input() lableStyle: string = 'block text-sm font-medium text-gray-700';

  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  public textCount = 0;
  public textLimit = 300;
  public exceedsMaxLength = false;

  ngOnInit(): void {
    this.textLimit =
      this.setLimit > 0 ? this.setLimit : appConfig?.textAreaLimit ?? 300;
    this.textCount = this.controlName?.value?.length ?? 0;
    this.addLimitValidation();
    this.controlName.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((v) => {
        this.textCount = v?.length;
        this.addLimitValidation();
      });
  }

  private addLimitValidation() {
    const validity = this.textCount > this.textLimit;
    this.exceedsMaxLength = validity;
    this.controlName.addValidators([Validators.maxLength(this.textLimit)]);
    this.controlName.updateValueAndValidity({ emitEvent: false });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
  public isRequired(): boolean {
    // check form if they have a required validation
    return this.controlName.hasValidator(Validators.required);
  }
}
