import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'select-input',
  template: `<div class="mb-4 form-group">
    <label
      *ngIf="label !== ''"
      [for]="controlName"
      class="block text-sm font-medium text-gray-700"
      >{{ label
      }}<span *ngIf="isRequired() && label !== ''" class="text-red-500">
        *
      </span></label
    >
    <select
      *ngIf="inputStyle == 'form'"
      [formControl]="controlName"
      (change)="onSelect.emit(controlName.value)"
      [id]="controlName"
      [name]="controlName"
      class="mt-1 h-8 w-full border text-black border-gray-300 px-4 rounded-md form-control"
      [ngClass]="{ 'bg-gray-100': controlName.disabled }"
      [class.is-invalid]="controlName.invalid && controlName.touched"
    >
      <option *ngIf="showPlaceholder" [value]="null" disabled>
        {{ placeholder }}
      </option>
      <option
        *ngFor="let option of options; index as index"
        [value]="optionValue ? option[optionValue] : option.toLowerCase()"
        [disabled]="
          disabledOptions.includes(
            optionValue ? option[optionValue] : option.toLowerCase()
          )
        "
      >
        {{ optionLabel ? option[optionLabel] : option }}
      </option>
    </select>

    <select
      *ngIf="inputStyle == 'header'"
      [formControl]="controlName"
      (change)="onSelect.emit(controlName.value)"
      [id]="controlName"
      [name]="controlName"
      class="mt-1 h-9 w-full borde text-blue-500 font-bold border-gray-300 px-4 rounded-md form-control"
      [ngClass]="{
        'border-[2px] border-blue-500 ': type == 'primary'
      }"
      [class.is-invalid]="controlName.invalid && controlName.touched"
    >
      <option [value]="null" disabled>
        {{ placeholder }}
      </option>
      <option
        *ngFor="let option of options; index as index"
        [value]="optionValue ? option[optionValue] : option.toLowerCase()"
        [disabled]="
          disabledOptions.includes(
            optionValue ? option[optionValue] : option.toLowerCase()
          )
        "
      >
        {{ optionLabel ? option[optionLabel] : option }}
      </option>
    </select>
    <form-validator [controlName]="controlName"></form-validator>
  </div>`,
})
export class SelectInputComponent {
  @Input() label: string = '';
  @Input() type: string = 'none';
  @Input() readOnly: boolean = false;
  @Input() showPlaceholder: boolean = true;
  @Input() placeholder: string = 'Select an option';
  @Input() controlName: FormControl = new FormControl('');
  @Input() options = [];
  @Input() optionValue: string = '';
  @Input() optionLabel: string = '';
  @Input() inputStyle: 'header' | 'form' = 'form';
  @Input() disabledOptions: string[] = [];
  @Output() onSelect: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit() {
    if (this.readOnly) {
      this.controlName.disable();
    }
  }

  public isRequired(): boolean {
    // check form if they have a required validation
    return this.controlName.hasValidator(Validators.required);
  }
}
