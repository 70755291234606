import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FuseLoadingService } from '@fuse/services/loading';
import { openSnackBar } from 'app/shared/utils/snackbar';
import {
  ReplaySubject,
  Subject,
  debounceTime,
  distinctUntilChanged,
  take,
  takeUntil,
} from 'rxjs';

@Component({
  selector: 'search-dropdown',
  template: `
    <div class="flex gap-1 flex-col">
      <label
        *ngIf="label && label !== ''"
        class="block text-sm font-medium text-gray-700"
        >{{ label
        }}<span *ngIf="isRequired() && label !== ''" class="text-red-500">
          *
        </span></label
      >
      <mat-form-field class="h-[52px]">
        <div class="flex flex-row items-center w-full pb-[15px]">
          <mat-select
            class="pb-[2px]"
            (selectionChange)="onSelect($event)"
            [formControl]="optionCtrl"
            #singleSelect
            [panelClass]="{
              'custome-search-dropdown-panel-nocheck  removecheckbox':
                !multiple,
              'custome-search-dropdown-panel dropdowncheckbox': multiple
            }"
            [multiple]="multiple"
          >
            <mat-option>
              <ngx-mat-select-search
                placeholderLabel="Search"
                [formControl]="optionFilterCtrl"
                noEntriesFoundLabel="'No results found'"
              ></ngx-mat-select-search>
            </mat-option>

            <li *ngFor="let item of filteredOption | async; let index = i">
              <mat-option
                *ngIf="item"
                [value]="item"
                [disabled]="disableAllOptions && !item._select_all"
              >
                <p class="p-0 m-0" *ngIf="!!advOptionParsingCallback">
                  {{ advOptionParsingCallback(item) }}
                </p>
                <p
                  class="p-0 m-0"
                  *ngIf="firstTitle && !secondTitle && !ObjectKey"
                >
                  {{ item?.[firstTitle] }}
                </p>
                <p *ngIf="firstTitle && secondTitle && !ObjectKey">
                  {{ item?.[firstTitle] }}
                  {{ item?.[secondTitle] }}
                </p>
                <p *ngIf="firstTitle && secondTitle && ObjectKey">
                  {{ item?.[ObjectKey][firstTitle] }}
                  {{ item?.[ObjectKey][secondTitle] }}
                </p>
                <p *ngIf="firstTitle && ObjectKey && !secondTitle">
                  {{ item?.[ObjectKey][firstTitle] }}
                </p>
              </mat-option>
            </li>
          </mat-select>
          <mat-icon
            class="text-black pb-[4px] mr-[-17px] icon-size-5"
            (click)="select.open()"
            [svgIcon]="'mat_outline:keyboard_arrow_down'"
          >
          </mat-icon>
        </div>
      </mat-form-field>
    </div>
  `,
})
export class SearchDropdownComponent implements OnInit {
  @ViewChild('select') select: MatSelect;
  @Input() initialValue: any;
  @Input() controlName: FormControl = new FormControl('');
  @Input() label: string = null;
  @Input() firstTitle: string = null;
  @Input() secondTitle: string = null;
  @Input() valueFilter: string = null;
  @Input() Options: any[] = [];
  @Input() resource: any = null;
  @Input() resourceFilters: FormControl = new FormControl('');
  @Input() multiple: boolean = false;
  @Input() ObjectKey: string = null;
  @Input() initDisable: boolean = false;
  @Input() customFilter: any = null;
  @Input() advFilter: any = null;
  @Input() advSearchEnable: boolean = false;
  @Input() advFilterCallback: any = undefined;
  @Input() advOptionParsingCallback: any = undefined;
  @Output() selectedOption: EventEmitter<any> = new EventEmitter();

  public resourceFilterValues: any = null;

  public optionCtrl: FormControl<any> = new FormControl<any>(null);
  public filter: any = {};

  public optionFilterCtrl: FormControl<string> = new FormControl<string>('');

  public filteredOption: ReplaySubject<any[]> = new ReplaySubject<[]>(1);

  @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;

  protected _onDestroy = new Subject<void>();

  public disableAllOptions = false;

  constructor(
    protected loadingService: FuseLoadingService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.resourceFilterValues = this.resourceFilters.getRawValue();
    this.optionCtrl.setValue(this.initialValue);
    this.resourceFilters.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe((values) => {
        //this.optionCtrl.reset();
        this.optionCtrl.enable();
        // this.selectedOption.emit(null);
        //this.filteredOption.next([]);
        this.resourceFilterValues = values;
      });
    this.optionFilterCtrl.valueChanges
      .pipe(
        takeUntil(this._onDestroy),
        distinctUntilChanged(),
        debounceTime(400)
      )
      .subscribe(() => {
        this.filterOptions();
      });
    this.filterOptions();

    if (this.initDisable) {
      this.optionCtrl.disable();
    }

    if (this.advFilter) {
      this.optionCtrl.enable();
    }
  }

  onSelect(event) {
    this.disableAllOptions = false;

    // Check if event.value is an array
    if (Array.isArray(event.value)) {
      const selectAllIndex = event.value.findIndex((e) => !!e._select_all);

      if (selectAllIndex !== -1) {
        this.disableAllOptions = true;

        // Force unselect all options and then reselect "select all"
        this.optionCtrl.setValue([event.value[selectAllIndex]]);
        event.value = [event.value[selectAllIndex]];
      }
    }

    this.selectedOption.emit(event);
  }

  ngAfterViewInit() {
    this.setInitialValue();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  protected setInitialValue() {
    this.filteredOption
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        this.singleSelect.compareWith = (a: any, b: any) =>
          a && b && a.id === b.id;
      });
  }

  protected filterOptions() {
    if (!this.Options) {
      return;
    }

    let search = this.optionFilterCtrl.value;

    if (!search) {
      this.filteredOption.next(this.Options.slice());
      return;
    } else {
      search = search.toLowerCase();

      // api search filter
      if (this.resource && !this.valueFilter) {
        this.filterSearch(search);
      } else if (this.advFilterCallback) {
        this.filteredOption.next(this.advFilterCallback(this.Options, search));
      } else {
        //local options filter for enums
        this.filteredOption.next(
          this.Options.filter(
            (item) => item[this.valueFilter].toLowerCase().indexOf(search) > -1
          )
        );
      }
    }
  }

  public filterSearch(filterKey) {
    let filter = {};
    if (filterKey) {
      this.loadingService.show();

      filter = {
        search: filterKey,
      };

      if (this.resourceFilterValues) {
        Object.keys(this.resourceFilterValues).map(
          (key) => (filter[key] = this.resourceFilterValues[key])
        );
      }

      if (
        !this.customFilter &&
        !this.advFilter &&
        this.resourceFilterValues &&
        !this.advSearchEnable
      ) {
        this.filter = {
          filter: JSON.stringify(filter),
        };
      } else if (
        this.customFilter &&
        !this.advFilter &&
        this.resourceFilterValues &&
        !this.advSearchEnable
      ) {
        this.filter = {
          ...this.customFilter,
          filter: JSON.stringify(filter),
        };
      } else if (
        this.customFilter &&
        !this.advFilter &&
        !this.resourceFilterValues &&
        !this.advSearchEnable
      ) {
        this.filter = this.customFilter;
        this.filter['filter'] = JSON.stringify(filter);
      } else if (
        !this.customFilter &&
        this.advFilter &&
        !this.resourceFilterValues &&
        !this.advSearchEnable
      ) {
        this.filter['advFilter'] = JSON.stringify(this.advFilter);
        this.filter['advSearch'] = filter['search'];
      } else if (
        this.customFilter &&
        this.advFilter &&
        !this.resourceFilterValues &&
        !this.advSearchEnable
      ) {
        this.filter = this.customFilter;
        this.filter['advFilter'] = JSON.stringify(this.advFilter);
        this.filter['advSearch'] = filter['search'];
      } else if (
        !this.customFilter &&
        !this.advFilter &&
        this.resourceFilterValues &&
        this.advSearchEnable
      ) {
        this.filter = {
          filter: JSON.stringify(this.resourceFilterValues),
        };
        this.filter['advSearch'] = filter['search'];
      } else {
        this.filter = {
          filter: JSON.stringify(filter),
        };
      }

      this.resource.list(this.filter).subscribe({
        next: (res) => {
          let returnData = [];
          returnData = res.data;
          this.Options = returnData;

          if (this.optionCtrl.value?.length > 0) {
            this.optionCtrl.value.forEach((selected) => {
              // Check if the item with the same id exists in Options
              const existingItemIndex = this.Options.findIndex(
                (item1) => item1.id === selected.id
              );

              if (existingItemIndex !== -1) {
                // If the item with the same id exists, merge the properties
                this.Options[existingItemIndex] = {
                  ...this.Options[existingItemIndex],
                  ...selected,
                };
              } else {
                // If the item with the same id doesn't exist, add it to Options
                this.Options.push(selected);
              }
            });
          } else if (this.optionCtrl.value) {
            const selected = this.optionCtrl.value;

            // Check if the item with the same id exists in Options
            const existingItemIndex = this.Options.findIndex(
              (item1) => item1.id === selected.id
            );

            if (existingItemIndex !== -1) {
              // If the item with the same id exists, merge the properties
              this.Options[existingItemIndex] = {
                ...this.Options[existingItemIndex],
                ...selected,
              };
            } else {
              // If the item with the same id doesn't exist, add it to Options
              this.Options.push(selected);
            }
          }
          this.filteredOption.next(this.Options.slice());
          this.loadingService.hide();
        },
        error: (err) => {
          openSnackBar(
            'Something went wrong while loading the options. Please try to reload the page',
            this.snackBar
          );
          this.loadingService.hide();
        },
      });
    }
  }
  public isRequired(): boolean {
    // check form if they have a required validation
    return this.controlName.hasValidator(Validators.required);
  }
}
